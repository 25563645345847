
class PSUIPlugin
{
	constructor(Grape)
	{
		this.Grape = Grape;

		if (window)
			window.PSUIPlugin = this;
	}

	async onInit()
	{
	}

	async onLoad()
	{
	}

	async onSessionChange(session)
	{
	}

}

export default PSUIPlugin;

